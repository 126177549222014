import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_21lung from "../Forms/FormsImg_21lung";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserMultiLung from "../user/UserMultiLung";
import User21_lung from "../user/User21_lung";
const MainDashImg_21lung = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_21lung/>
      <User21_lung/>
    
    </div>
  );
};

export default MainDashImg_21lung;

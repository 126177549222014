import React from "react";
import Cards from "../Cards/Cards";
import Forms from "../Forms/Forms";
import Table from "../Table/Table";
import {UilHeartRate} from "@iconscout/react-unicons";

import "./MainDash.css";
const MainDash = () => {
  return (
    <div className="MainDash">
      <h1>
        <UilHeartRate size={70} />
        AI FOR CANCER
        
      </h1>
      {/* <Cards /> */}
      {/* <Table /> */}
      <Forms/>

    </div>
  );
};

export default MainDash;

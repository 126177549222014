import React from 'react';
import ImageUpload from '../ImgUpload/ImgUpload';
import DragAndDrop from '../DragDrop/DragAndDrop';
import "./forms.css";
import { useState } from 'react'
import { useEffect, useRef } from "react";
// import "./imgUpload.css";
import axios from 'axios';
export default function FormsImg_21lung() {
  const [image, setImage] = useState(null)
  const [num1, setNum1] = React.useState('');
  const myCanvas = useRef();
  console.log(image)
  console.log(num1)
  function handleImage(event) {
    event.preventDefault()
    const file = event.target.files[0];
    const reader = new FileReader();

    console.log(file)
    reader.onload = function () {
      console.log("here")
      const img = new Image();
      img.src = reader.result;
      //convert img.src to a string that can be sent to backend
      setImage(img.src)
    }
    reader.readAsDataURL(file);
  }
  async function handleApi(e) {
    e.preventDefault()
    console.log(image)
    const res = await fetch('https://api021.aiforcancer.com/calculatepixels', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ image }),
    })
    const data = await res.json()
    console.log(data)
    setNum1(data.result)
  }
  useEffect(() => {
    const context = myCanvas.current.getContext("2d");
    const image1 = new Image();
    image1.src = image ? image : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Picture_icon_BLACK.svg/1200px-Picture_icon_BLACK.svg.png";
    image1.onload = () => {
      context.drawImage(image1, 0, 0, 200, 200);
    };
  }, [image]);
  return (
    <div className='create'>

      <h2>Make a Prediction</h2>
      <div className='align'>
        <div className='my_box'>

          <h3>Chest Xray.</h3>
          <li>1. Normal, Sick</li>
          <li>2. Tuberculosis, Covid, Lung Opacity</li>
          <li>3. Viral Pneumonia, Bacterial Pneumonia</li>
          
          <div> UPLOAD IMAGE HERE</div>


          
          <input type='file' name='file' onChange={handleImage} />
          <button onClick={handleApi}> Submit </button>
          <h1/>
          <div>
            Prediction:
          </div>
          {num1}
          <textarea
            value={num1}
          > </textarea>
        </div>
        <canvas ref={myCanvas} width={200} height={250} />;

      </div>
    </div>
  )
}

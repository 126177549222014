import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import YoutubeEmbed from "../youtube";
import "./user.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate, UilEnvelopeEdit,UilMapPinAlt} from "@iconscout/react-unicons";

export default function UserHomepage() {
  return (
    <div className="user">
      <div className="userTitleContainer">
        
        <h1 className="userTitle">Welcome to AI For Cancers</h1>
        <Link to="/newUser">
          {/* <button className="userAddButton">Create</button> */}
        </Link>
      </div>
      {/* <YoutubeEmbed/> */}
      <div className="userContainer">
        <div className="userShow">
          <div className="userShowTop">
            {/* <img
              src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="userShowImg"
            /> */}
            <div className="userShowTopTitle">

              {/* <span className="userShowUsername">DISCLAIMER: This Website Is Meant For Research And Personal Testing Only!!</span> */}
              {/* <p>{"\n"}</p> */}
              {/* <p className="userShowUsername">You pay for food, you don’t pay for health.</p> */}
              {/* <p className="">The authors do not focus on money. From the moment we are born, we are set to face the inevitable – death. We focus on enhancing the life of patients so that they can build and reinforce relationships with their loved ones Life is not about making money. It is enhancing the quality of life for every single being.</p> */}
              {/* <p className="">These trained AI models are freely available for use.</p> */}
              {/* <p className="">We provide a platform to integrate healthcare professionals, computer scientists and researchers to better humanity. We encourage adoption of AI across health systems to improve accuracy of clinical diagnosis, decisions and treatments. We envisage an amalgamation of vibrant AI Systems with medical techniques.</p> */}
              {/* <span>Our aim is to improve the life of patients' one at a time. Each patient’s life is as important to us as it is to you.</span> */}
              
              {/* <span></span> */}


              <span className="userShowUserTitle"></span>
            </div>
          </div>
          <div className="userShowBottom">
            {/* <span className="userShowTitle">Data Details</span> */}
            {/* <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Contact: aiforcancer@gmail.com</span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Category: 2</span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Category: 3</span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Engine: sklearn | Tensorflow</span>
            </div>
            <div className="userShowInfo">
              <CalendarToday className="userShowIcon" />
              <span className="userShowInfoTitle">Date Created: 16 August 2020</span>
            </div> */}
            {/* <span className="userShowTitle">Model Credentials</span> */}
            <div className="userShowInfo">
              <PhoneAndroid style={{ fontSize: '20px' }} />
              <span className="userShowInfoTitleBold">Data Credentials:</span>
              <span className="userShowInfoTitle">Kaggle</span>
            </div>

            <div className="userShowInfo">
              <MailOutline  style={{ fontSize: '20px' }} />
              <span className="userShowInfoTitleBold">Contact:</span>
              <a href = 'aiforcancer@gmail.com'>

              <span className="userShowInfoTitle">aiforcancer@gmail.com</span>              
              </a>
              </div>
            <div className="userShowInfo">
              <UilMapPinAlt />
                {/* <LocationSearching /> */}
                <span className="userShowInfoTitle">Singapore</span>

                </div>
          </div>
        </div>
        {/* <div className="userUpdate">
          <span className="userUpdateTitle">Edit</span>
          <form className="userUpdateForm">
            <div className="userUpdateLeft">
              <div className="userUpdateItem">
                <label>Username</label>
                <input
                  type="text"
                  placeholder="annabeck99"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Full Name</label>
                <input
                  type="text"
                  placeholder="Anna Becker"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Email</label>
                <input
                  type="text"
                  placeholder="annabeck99@gmail.com"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Phone</label>
                <input
                  type="text"
                  placeholder="+1 123 456 67"
                  className="userUpdateInput"
                />
              </div>
              <div className="userUpdateItem">
                <label>Address</label>
                <input
                  type="text"
                  placeholder="New York | USA"
                  className="userUpdateInput"
                />
              </div>
            </div>
            <div className="userUpdateRight">
              <div className="userUpdateUpload">
                <img
                  className="userUpdateImg"
                  src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                  alt=""
                />
                <label htmlFor="file">
                  <Publish className="userUpdateIcon" />
                </label>
                <input type="file" id="file" style={{ display: "none" }} />
              </div>
              <button className="userUpdateButton">Update</button>
            </div>
          </form>
        </div> */}
      </div>
    </div>
  );
}
import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_4colon from "../Forms/FormsImg_4colon";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User4_colon from "../user/User4_colon";
const MainDashImg_4colon = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_4colon/>
      <User4_colon/>
    </div>
  );
};

export default MainDashImg_4colon;

// https://fontawesome.com/icons/staff-snake?s=solid&f=classic
// https://iconscout.com/unicons/explore/line

import './App.css'
import MainDash from './components/MainDash/MainDash';
import MainDash0 from './components/MainDash/MainDash0';
import MainDashImg from './components/MainDash/MainDashImg';

import MainDashImg_1skin from './components/MainDash/MainDashImg_1skin';
import MainDashImg_3lung from './components/MainDash/MainDashImg_3lung';
import MainDashImg_4colon from './components/MainDash/MainDashImg_4colon';
import MainDashImg_18brain from './components/MainDash/MainDashImg_18brain';
import MainDashImg_21lung from './components/MainDash/MainDashImg_21lung';
import MainDashImg_15_18_19_MultiBrain from './components/MainDash/MainDashImg_15_18_19_MultiBrain';


import MainDashDocker from './components/MainDash/MainDashDocker';
import MainDashImgDocker from './components/MainDash/MainDashImgDocker';
import RightSide from './components/RigtSide/RightSide';
import Sidebar from './components/Sidebar';
import Sidebar_cp from './components/Sidebar_cp';

import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";
import RecommendIcon from '@mui/icons-material/Recommend';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faColonSign,faLungs, faHeart, faHeartbeat, faVenus, faHandDots, faFootball , faPoo, faFootballBall, faBeer, faLungsVirus,faHeartPulse, faVirus} from '@fortawesome/free-solid-svg-icons'


import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import UserList from './components/userList/UserList';
import ImgUpload from './components/ImgUpload/ImgUpload';
import MainDashContact from './components/MainDash/MainDashContact';
function App() {
  return (
    <Router>
      <div className="App">
        <div className="AppGlass">
          <Sidebar_cp />
          <Switch>
            <Route exact path="/" component={MainDash0} />
            <Route path="/BreastCancer" component={MainDash} />
            <Route path="/SkinCancer" component={MainDashImg_1skin} />
            <Route path="/LungCancer" component={MainDashImg_3lung} />
            <Route path="/ColerectalCancer" component={MainDashImg_4colon} />
            <Route path="/BrainCancer" component={MainDashImg_18brain} />
            <Route
              path="/BrainCancer_15_18_19_MultiBrain"
              component={MainDashImg_15_18_19_MultiBrain}
            />
            <Route path="/MultiLung" component={MainDashImg_21lung} />
            <Route path="/Contact" component={MainDashContact} />
            <Route path="/Docker1" component={MainDashImg} />
            <Route path="/Docker2" component={MainDashImg} />
          </Switch>
        </div>
      </div>
    </Router>
  );
}


export default App;

import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./user.css";

export default function User4_colon() {
  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Model Details</h1>
        <Link to="/newUser">
          {/* <button className="userAddButton">Create</button> */}
        </Link>
      </div>
      <div className="userContainer">
        <div className="userShow">

          <div className="userShowBottom">
            <span className="userShowTitle">Data Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Accuracy: 100.00% </span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Predictions : 1. Colon adenocarcinoma, 2. Colon benign tissue</span>
            </div>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitleBold">Data Credentials:</span>
              <a className="userShowInfoTitle" target="_blank" href ="https://www.kaggle.com/" > Kaggle ColonCancerDataset </a>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitleBold">Training and Accuracy Data Preview:</span>
              <a className="userShowInfoTitle" target="_blank" href ="https://drive.google.com/drive/folders/10w2EtBQgyPx9l7y3lirT1LGl89nY8E1g?usp=share_link" > Google Drive - Colon (Train)</a>
              </div>
          </div>
        </div>
     </div>
    </div>
  );
}
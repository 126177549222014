import {
  CalendarToday,
  LocationSearching,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import "./user.css";

export default function User3_lung() {
  return (
    <div className="user">
      <div className="userTitleContainer">
        <h1 className="userTitle">Model Details</h1>
        <Link to="/newUser">
          {/* <button className="userAddButton">Create</button> */}
        </Link>
      </div>
      <div className="userContainer">
        <div className="userShow">

          <div className="userShowBottom">
            <span className="userShowTitle">Data Details</span>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Accuracy: 99.98% </span>
            </div>
            <div className="userShowInfo">
              <PermIdentity className="userShowIcon" />
              <span className="userShowInfoTitle">Predictions : 1. Lung benign tissue, 2. Lung adenocarcinoma, 3. Lung squamous cell carcinoma</span>
            </div>
            <div className="userShowInfo">
              <PhoneAndroid className="userShowIcon" />
              <span className="userShowInfoTitleBold">Data Credentials:</span>
              <a className="userShowInfoTitle" target="_blank" href ="https://www.kaggle.com/" > Kaggle LungCancerDataset </a>
            </div>
            <div className="userShowInfo">
              <MailOutline className="userShowIcon" />
              <span className="userShowInfoTitleBold">Training and Accuracy Data Preview:</span>
              <a className="userShowInfoTitle" target="_blank" href ="https://drive.google.com/drive/folders/1I7PfIolSf-LgF36gLaICVlV0FfCtPzbN?usp=share_link" > Google Drive - Lung (Train)</a>
              </div>
          </div>
        </div>
     </div>
    </div>
  );
}
import React, { useState } from 'react';
import './Sidebar_cp.css';
import Logo from "../imgs/logo.png";
import pic from '../crab.png';
import { UilSignOutAlt, UilEstate, UilHome, UilVenus, UilHeartRate, UilEnvelopeEdit } from "@iconscout/react-unicons";
import { UilBrain } from '@iconscout/react-unicons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSnake, faColonSign, faLungs, faHeart, faHeartbeat, faVenus, faHandDots, faFootball, faPoo, faFootballBall, faBeer, faLungsVirus, faHeartPulse, faVirus, faBrain } from '@fortawesome/free-solid-svg-icons';
import { faHand } from '@fortawesome/free-regular-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import MainDash from './MainDash/MainDash';

const Sidebar_cp = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`Sidebar ${sidebarOpen ? 'sidebarOpen' : ''}`}>
      <div className="logo">
        <a href='/HomePage'>
          <img src={pic} alt="" className="crablogo" />
        </a>
        <span >
          A<span>I</span>C
        </span>
      </div>
      <div className="menu">
        <div className="menuItem">
          <div>
            <UilHome />
          </div>
          <Link to='/'>
            <span>HomePage</span>
          </Link>
        </div>
        <div className="menuItem">
          <div>
            <FontAwesomeIcon icon={faLungs} style={{ fontSize: '20px' }} />
          </div>
          <Link to='/LungCancer'>
            <span>LungCancer</span>
          </Link>
        </div>
        <div className="menuItem">
          <div>
            <FontAwesomeIcon icon={faPoo} style={{ fontSize: '20px' }} />
          </div>
          <Link to='/ColerectalCancer'>
            <span>ColerectalCancer</span>
          </Link>
        </div>
        <div className="menuItem">
          <div>
            <UilBrain></UilBrain>
          </div>
          <Link to='/BrainCancer'>
            <span>BrainCancer</span>
          </Link>
        </div>
        <div className="menuItem">
          <div>
            <FontAwesomeIcon icon={faLungsVirus} />
          </div>
          <Link to='/MultiLung'>
            <span>MultiLung(TBC)</span>
          </Link>
        </div>
        <div className="menuItem">
          <div>
            <UilEnvelopeEdit />
          </div>
          <Link to='/Contact'>
            <span>Contact</span>
          </Link>
        </div>
      </div>
      <div className={`bars ${sidebarOpen ? 'close' : ''}`} onClick={handleSidebarToggle}>
        {sidebarOpen ? (
          <FontAwesomeIcon icon={faTimes} className="close" />
        ) : (
          <FontAwesomeIcon icon={faBars} className="open" />
        )}
      </div>
    </div>
  );
};

export default Sidebar_cp;

import {
    CalendarToday,
    LocationSearching,
    MailOutline,
    PermIdentity,
    PhoneAndroid,
    Publish,
  } from "@material-ui/icons";
  import { Link } from "react-router-dom";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate, UilEnvelopeEdit,UilMapPinAlt} from "@iconscout/react-unicons";

import YoutubeEmbed from "../youtube";
  import "./user.css";
  
  export default function UserHomepage() {
    return (
      <div className="user">
        <div className="userTitleContainer">
          
          {/* <h1 className="userTitle">LOOKING FOR a HONEYDEW: Tele @malcolm1232 THERE was A BUG in Hge</h1> */}
          <h1 className="userTitle">Welcome to AI For Cancer</h1>

          <Link to="/newUser">
            {/* <button className="userAddButton">Create</button> */}
          </Link>
        </div>
        <YoutubeEmbed embedId="e0TB3nVj7pY"/>
        {/* <a href ="https://youtu.be/e0TB3nVj7pY" target="_blank">https://youtu.be/e0TB3nVj7pY</a> */}
        <div className="userContainer">
          <div className="userShow">
            <div className="userShowTop">
             
              <div className="userShowTopTitle">

                <span className="userShowUsername">DISCLAIMER: This Website Is Meant For Research And Testing Only!!</span>
                {/* <p>{"\n"}</p> */}
                {/* <p className="userShowUsername">You pay for food, you don’t pay for health.</p> */}
                <p className="">The authors do not focus on money. From the moment we are born, we are set to face the inevitable – death. We focus on enhancing the life of patients so that they can build and reinforce relationships with their loved ones. Life is not about making money. It is enhancing the quality of life for every single being.</p>
                <p className="bold-text">These trained AI models are freely available for use.</p>
                {/* We provide a platform to integrate healthcare professionals, computer scientists and researchers to better humanity.  */}
                <p className="">We encourage adoption of AI across health systems to improve accuracy of clinical diagnosis, decisions and treatments. We envisage an amalgamation of vibrant AI Systems with medical techniques.</p>
                <span>Our aim is to improve the life of patients' one at a time. Each patient’s life is as important to us as it is to you.</span>
                
                <span></span>


                <span className="userShowUserTitle"></span>
              </div>
            </div>
            <div className="userShowBottom">
              
              <div className="userShowInfo">
                <PhoneAndroid className="userShowIcon" />
                <span className="userShowInfoTitleBold">Data Credentials:</span>
                <span className="userShowInfoTitle">Kaggle</span>
              </div>

              <div className="userShowInfo">
                <MailOutline className="userShowIcon" />

                <span className="userShowInfoTitleBold" >Contact:</span>

                <a href = 'aiforcancer@gmail.com'>
                  <span className="userShowInfoTitle">aiforcancer@gmail.com</span>              
                </a>
                </div>
              <div className="userShowInfo">
                <UilMapPinAlt/>
                {/* <LocationSearching /> */}
                <span className="userShowInfoTitle">Singapore</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }

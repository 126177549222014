import React from "react";
import Cards from "../Cards/Cards";
import FormsImg from "../Forms/FormsImgDocker";
import Table from "../Table/Table";
import "./MainDash.css";
const MainDashImg = () => {
  return (
    <div className="MainDash">
      <h1>AI FOR CANCER</h1>
      <Cards />
      {/* <Table /> */}
      <FormsImg/>

    </div>
  );
};

export default MainDashImg;

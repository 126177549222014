import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_3lung from "../Forms/FormsImg_3lung";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User3_lung from "../user/User3_lung";
const MainDashImg_3lung = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_3lung/>
      <User3_lung/>
      

    </div>
  );
};

export default MainDashImg_3lung;

import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_18brain from "../Forms/FormsImg_18brain";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User15_18_19MultiBrain from "../user/User15_18_19MultiBrain";
const MainDashImg_18brain = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_18brain/>
      <User15_18_19MultiBrain/>
      

    </div>
  );
};

export default MainDashImg_18brain;

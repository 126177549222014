import React from "react";
import Cards from "../Cards/Cards";
import Forms from "../Forms/Forms";
import Table from "../Table/Table";
import UserHomepage from "../user/UserHomepage";
import {UilHeartRate} from "@iconscout/react-unicons";

import "./MainDash.css";
const MainDash0 = () => {
  return (
    <div className="MainDash">
      
      <h1 className="HeaderWelcome">
        <UilHeartRate size={30} />
        AI FOR CANCER
      </h1>

      {/* <Cards /> */}

      {/* <Table /> */}
      {/* <Forms/> */}
      <UserHomepage/>
    </div>
  );
};

export default MainDash0;

import React from "react";
import Cards from "../Cards/Cards";
import FormsImg_1skin from "../Forms/FormsImg_1skin";
import Table from "../Table/Table";
import pic from "./crab.png"
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import User from "../user/User";
import FeaturedInfo from "../featuredInfo/FeaturedInfo";
const MainDashImg_1skin = () => {
  return (
    <div className="MainDash">
      <h1>
      {/* <img
        // src="https://images.pexels.com/photos/1152994/pexels-photo-1152994.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
        src={pic}
        className="userShowImgIcon"
      /> */}
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      {/* <Cards /> */}
      {/* <Table /> */}
      <FormsImg_1skin/>
      <User/>
      {/* <FeaturedInfo/> */}
    </div>
  );
};

export default MainDashImg_1skin;

import React from "react";
import Cards from "../Cards/Cards";
import FormsImgskin from "../Forms/FormsImg_1skin";
import Table from "../Table/Table";
// import crab from

import "./MainDash.css";
import { UilSignOutAlt , UilEstate, UilHome, UilVenus,UilHeartRate} from "@iconscout/react-unicons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MainDashImg = () => {
  return (
    <div className="MainDash">
      <h1>
      <UilHeartRate size={70} />
        AI FOR CANCER
      </h1>
      
      <Cards />
      {/* <Table /> */}
      <FormsImgskin/>

    </div>
  );
};

export default MainDashImg;
